<template>
<div>
  <nav>
    <span> - </span>
    <router-link to="/register" v-if="!isLoggedIn"><i class="fa fa-id-card-o"></i> Register</router-link>
    <router-link to="/login" v-if="!isLoggedIn"><i class="fa fa-sign-in"></i> Login</router-link>
    <router-link to="/feed" v-if="isLoggedIn"><i class="fa fa-archive"></i> Downloads</router-link>
    <router-link to="/settings" v-if="isLoggedIn"><i class="fa fa-cog"></i></router-link>

    <button @click="handleSignOut" v-if="isLoggedIn" class="btnLogout"><i class="fa fa-sign-out"></i> Logout</button>
    <!-- <span> - {{ isLoggedIn }}</span> -->
  </nav>
  <div class="div2">
  <img src=".\assets\all_pass_banner.png" alt="Logo">
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in" appear>
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
  <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

</div>
</template>


<script setup>
import { onMounted, ref } from 'vue';
import { getAuth, onAuthStateChanged, signOut, singOut } from "firebase/auth";
import { createRouterMatcher } from 'vue-router';
import router from './router';

const isLoggedIn = ref(false);
let userName = "";
let auth;
onMounted(() => {
  console.log("MOUNTED");
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedIn.value = true;
      userName = user.displayName;
    } else {
      userName = "";
      isLoggedIn.value = false;
      router.push("/login");
    }
  });
});

const handleSignOut = () => {
  signOut(auth).then(() => {
    router.push("/login");
  });
};
</script>


<script>
import db from './firebase/init.js'
import { doc, setDoc } from 'firebase/firestore'
console.log('CACA:')
const date = new Date();
const month = date.getMonth() + 1; // Add 1 to convert from 0-based to 1-based month numbering
const day = date.getDate();
const year = date.getFullYear();
const dateString = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year.toString()}`;

export default {
  methods: {
    async createUser() {
      await setDoc(doc(db,'users','US'),
      {
        name:'Test',
        date: dateString,
        caca:'xx'
      });
    }
  },
  created() {
    /* this.createUser() */
  }

}
/* name: 'App',
    data: () => ({

    }),
    beforeRouteEnter() {
      console.log("Hello, World!");
    } */
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
/*   color: #2c3e50;
  background-color: #3d426b; */
  margin-bottom: 50px;
/*   padding-bottom: 70px; */
/*   height: 800px; */
  margin-top: -10px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 90px;

  background-image: url("./assets/back1.svg");
  background-size: cover;

/* background: linear-gradient(5deg, rgba(2,0,36,1) 0%, #3d426b 35%, #d9d6eb 100%); */
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 30px;
  font-weight:400;
  font-style: normal;
}

nav {
/*   max-width: 550px;
  margin-left: auto;
  margin-right: auto; */
/*   border-radius: 20px; */
  padding: 20px;
  margin-top: -8px;
  background-color: #ffffff;
  box-shadow: 10px 5px 50px #dfdfe3;
}

nav a {
  box-shadow: 5px 5px 20px #d6d6d6;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 100;
  background-color: #eaeaea;
  /* font-weight: bold; */
  font-size: larger;
  color: #747c86;
  text-decoration: none;
  margin: 5px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
/*   background-color: #ffffff; */
  border-radius: 10px;

}

nav a.router-link-exact-active {
  color: #1f1f1f;
  border-bottom: 2px;
  /* text-shadow: 1px 2px 5px #ffffff; */
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #bbff00;
  box-shadow: 2px 2px 30px #bbff00;
  border-radius: 10px;
  
}
nav a:hover {
  background-color: #f8f8fa;
}
div {
  text-align: center;
}
.div2 {
  background-color: #ffffff;
  max-width: 800px;
  min-width: 890px;
  padding-bottom: 40px;
  text-align: center;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  box-shadow: 10px 5px 56px #b2b3d1;
}

input {
  font-size:normal;
  width: 80%;
  max-width: 400px;
  padding: 15px;
  border: 2px solid #b5b4b4;
  /* border-width: 5px; */
  border-radius: 12px;
}
input:hover {
  border-color: #787878;
  background-color: #e6f8fc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: 3px 3px 20px rgb(188, 224, 249);
}

button {
  
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #285ac4;
  color: #f2f2f2;
  min-width: 250px;
  padding: 20px;
  border-width: 0px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px #c1c8d2;
  font-size: small;
  cursor: pointer;
}

button:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background-color: #79a4ff;
  box-shadow: 5px 5px 10px #cdecf3;
}

.btnLogout {
  box-shadow: 5px 5px 20px #d6d6d6;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 100;
  background-color: #eaeaea;
  /* font-weight: bold; */
  font-size: larger;
  color: #747c86;
  text-decoration: none;
  margin: 5px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
/*   background-color: #ffffff; */
  border-radius: 10px;
}
.btnLogout:hover {
  color: #f3f3f3;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background-color: #363636;
  box-shadow: none;
}
.btn {
  background-color: rgb(36, 36, 37);
  border: none;
  color: white;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  z-index: 0;

}
.btn:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #1f1f1f;
  border-bottom: 2px;
  /* text-shadow: 1px 2px 5px #ffffff; */

  background-color: #bbff00;
  box-shadow: 2px 2px 30px #bbff00;
  border-radius: 10px;

}
.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 10px;
  box-shadow: 5px 5px 15px #b5b4b4;
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
  .login-with-google-btn:hover {
    background-color: #bbff00;
    box-shadow: 2px 2px 30px #bbff00;
  }
  
  .login-with-google-btn:active {
    background-color: #eeeeee;
  }
  
  .login-with-google-btn:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  .login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
  
  .errorMsg {
    padding: 0px;
    color: #f40808;
/*     background-color: #ec0101; */
/*     box-shadow: 2px 2px 30px #ff859d; */
/*     font-weight: bold; */
    font-size: 18px;
/*     width: 500px;
    margin-left: auto;
    margin-right: auto; */
    border-radius: 2px;
  }

  /* Transitions between routes... */
  .route-enter-form {
    opacity: 0;
  }
  .route-enter-active {
    transition: all .3s ease-out
  }
  .route-leave-active {
    transition: all .3s ease-out
  }
  .route-leave-to {
    opacity: 0;
  }
  .btnLink {
  margin-top: 30px;
  color: #1c1c1c;
  text-decoration: underline;
  background: #ffffff;
  box-shadow: 0px 0px 0px;
  z-index: 1;
  padding: 5px;
  z-index: 1;
}
.btnLink:hover{
  color: #1e3260;
  text-decoration: underline;
  background: #ffffff;
  box-shadow: 0px 0px 0px;
  z-index: 1;
  padding: 5px;
  z-index: 1;
}
</style>
