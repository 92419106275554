<template>
  <div>
    <transition name="fade">
            <div v-if="show">
              <div class="toast">
                <i class="fa fa-download"></i> Downloading <b>{{ toastMessage }}</b>...
              </div>
            </div>
          </transition>
    <iframe id="invisible" style="display:none;"></iframe>

    <h1>Downloads</h1>
    <ul class="cards" id="myUL">
      <li v-for="(item, index) in items" :key="index" class="cards__item">
        <div class="card">
          <div><img class="thumbImg" :src="require(`@/assets/${item.thumbUrl}`)" :alt="item.name"></div>
          <div class="card__content">
            
            <div class="card__title">{{ item.name }}</div>
            <button @click="openUrl(item.infoUrl, item.name)" class="btnInfo" href="https://google.com"><i class="fa fa-info"></i> Product Info</button>
            <button @click="download(item.url, item.name)" class="btn" href="https://google.com"><i class="fa fa-download"></i> Download</button>

          </div>
        </div>
      </li>

    </ul>

</div>
</template>




<script>
import { ref } from 'vue';
import db from '../firebase/init.js'
import { doc, getDoc } from 'firebase/firestore'
let date1 = "LOCAL";

import { useTaskStore } from '../stores/TaskStore'

export default {
  setup() {
    const TaskStore = useTaskStore();
    const show = ref(false);
    const toastMessage = ref('');
    const showToast = (message) => {
      toastMessage.value = message;
      show.value = true;
      setTimeout(() => {
        show.value = false;
      }, 2000); };
    return { TaskStore, show, showToast, toastMessage }
  },
    data(){
      return{
        items: [
          { name: 'MaxToMaya 2023', 
          thumbUrl: 'maxtomaya.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'MaxToC4D 2023',
          thumbUrl: 'maxtoc4d.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'MaxToModo',
          thumbUrl: 'maxtomodo.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for Maya',
          thumbUrl: 'ikmax_maya.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for Cinema 4D',
          thumbUrl: 'ikmax_c4d.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },

          { name: 'IKMAX 2023 for 3ds Max',
          thumbUrl: 'ikmax-3dsmax.jpg', infoUrl: 'https://www.google.com/', url: '#' },
        ]
      }
    },
    methods: {
    download(url, prodName) {
      var iframe = document.getElementById('invisible');
      iframe.src = url;
      this.showToast(prodName);
    },
    openUrl(url, prodName) {
      window.open(url)
    },
    async getCountry() {
      const docSnap = await getDoc(doc(db, 'members', 'marcelobruno@gmail.com'))
      if (docSnap.exists()){
        this.date1 = docSnap.data().date;
        this.member = docSnap.data().member;
        console.log(docSnap.data().date)
      }else{
        console.log('Document not found!')
      }
    }
  },
    created() {
      /* this.getCountry(); */
      console.log("Hello!");
    }
}

</script>

<style>

.toast {
  box-shadow:  5px 5px 50px #303030;
  background-color: hsl(0, 0%, 100%);
  color: #2e2e2e;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 70px;
  padding-right: 70px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.content{
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

img {
  height: auto;
  vertical-align: middle;
}

.btn {
  padding: 20px;
  width: 20px;
  box-shadow: 5px 5px 30px #000000ad;
}

.btnInfo {
  background-color: rgb(220, 228, 228);
  border-radius: 0px;
  color: #2e2e2e;
  /* height: 30px; */
  padding: 10px;
}

.btnInfo:hover{
  background-color: #bbff00;
}

.cards {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  align-content: space-around;
  
}

.cards__item {
  display: flex;
  padding: 1rem;
}

.card {
  width: 250px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.314);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card:hover{
  border-width: 2px;
  box-shadow: 0 20px 40px -14px rgba(158, 222, 106, 0.745);
}
.card__content {
  color:#303030;
  flex: 1 1 auto;
  flex-direction: column;
  
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  overflow: hidden;
  position: relative;
}

.thumbImg {
  background-image: url('../assets/maxtoc4d.jpg');
  background-color: aqua;
  height: 100%;
  width: 100%;
}

.card__image--river {
  background-image: url(https://unsplash.it/800/600?image=11);
}

.card__image--record {
  background-image: url(https://unsplash.it/800/600?image=39);
}

.card__image--fence {
  background-image: url(https://unsplash.it/800/600?image=59);
}

.card__title {
  font-size: 1rem;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card__text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 5px;
}



</style>