<template>
  <div>
    <h1>Register</h1>
    <p><input type="text" placeholder="Email" v-model="email"/></p>
    <p><input type="password" placeholder="Password" v-model="password"/></p>
    <p><input type="password" placeholder="Confirm Password" v-model="password2"/></p>
    <div class="errorMsg">
    <p v-if="errMsg">{{ errMsg }}</p> 
    </div>
    <p><button class="btn" @click="register2"><i class="fa fa-sign-in"></i> Register</button></p>
    <p><button type="button" @click="signInWithGoogle" class="login-with-google-btn" >Sign in with Google</button></p>
    <!-- <p><button class="btn" @click="signInWithGoogle"><i class="fa fa-google"></i> Sign in with Google</button></p> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { 
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { useRouter } from "vue-router";
/* import { log } from "console"; */
const email = ref("");
const password = ref("");
const password2 = ref("");
const errMsg = ref(); // ERROR MESSAGE
const router = useRouter();
let validForm = true;

const register2 = () => {
  validForm = true;
  errMsg.value = "";
  if (password.value !== password2.value) {
    errMsg.value = "The password and confirmation password do not match!";
    validForm = false;
  } else {
    errMsg.value = "";
  }
  if (password.value.length < 8) {
    errMsg.value =  "Password too short!";
    validForm = false;
  }
  if (validForm === true) {
    register();
  }
  
}

const register = () => {
  const auth = getAuth()
  createUserWithEmailAndPassword(auth, email.value, password.value)
  .then((data) => {
    console.log("Succesfully Registered!");
    console.log(auth.currentUser);
    router.push('/feed') // reditect to the feed
  })
  .catch((error) => {
  console.log(error.code);
  switch(error.code){
    case "auth/invalid-email":
      errMsg.value = "Invalid email!";
      break;
    case "auth/user-not-found":
      errMsg.value = "No account with that email was found!";
      break;
    case "auth/wrong-password":
      errMsg.value = "Incorrect Password!";
      break;
    default:
      errMsg.value = "Email/Password incorrect or already registered";
      break;
  }
});
};

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(getAuth(), provider)
  .then((result) => {
    console.log(result.user);
    router.push('/feed');
  })
  .catch((error) => {
 
  })
}

</script>