import { initializeApp } from "firebase/app";
import { getFIrestore, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC2UyOXs-DdIqd7GCycjsk3xQyNOxgUNFE",
  authDomain: "allpass-members.firebaseapp.com",
  projectId: "allpass-members",
  storageBucket: "allpass-members.appspot.com",
  messagingSenderId: "182187547446",
  appId: "1:182187547446:web:039817d2393b6cea983197",
  measurementId: "G-7EY5GLYCMN"
};

initializeApp(firebaseConfig);

const db = getFirestore();
export default db