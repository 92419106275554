<template>
  <div>
    <h1> Hello Again!</h1>
<!--     <p>{{ TaskStore.name }}</p> -->
    <p><input type="text" placeholder="Email" v-model="email"/></p>
    <p><input type="password" placeholder="Password" v-model="password"/></p>
    <div class="errorMsg">
    <p v-if="errMsg">{{ errMsg }}</p> 
    </div>
    <div>
    <button class="btn" @click="register"><i class="fa fa-sign-in"></i> Login</button>
    </div>
    <p><button type="button" @click="signInWithGoogle" class="login-with-google-btn" >Sign in with Google</button></p>
    <button type="button" class="btnLink" @click="userLogout"> Forgot Password?</button>

</div>
</template>


<script setup>
import { ref } from "vue";
import { 
getAuth,
signInWithEmailAndPassword,
GoogleAuthProvider,
signInWithPopup
} from "firebase/auth";
import { useRouter } from "vue-router";
import { sendPasswordResetEmail } from "firebase/auth";

const email = ref("");
const password = ref("");
const errMsg = ref(); // ERROR MESSAGE
const router = useRouter();
const register = () => {
const auth = getAuth()
signInWithEmailAndPassword(auth, email.value, password.value)
.then((data) => {
  console.log("Succesfully Signed In!");
  console.log(auth.currentUser);
  router.push('/feed') // reditect to the feed
})
  .catch((error) => {
  console.log(error.code);
  switch(error.code){
    case "auth/invalid-email":
      errMsg.value = "Invalid email!";
      break;
    case "auth/user-not-found":
      errMsg.value = "No account with that email was found!";
      break;
    case "auth/wrong-password":
      errMsg.value = "Incorrect Password!";
      break;
    default:
      errMsg.value = "Email or Password was incorrect!";
      break;
  }
});
}; 

const userLogout = () => {
  router.push('/passreset');
}

const signInWithGoogle = () => {
const provider = new GoogleAuthProvider();
signInWithPopup(getAuth(), provider)
.then((result) => {
  router.push('/feed');
  console.log(result.user);
})
.catch((error) => {

})
}


</script>

<!-- <style scoped>
.btnLink {
  color: #1c1c1c;
  text-decoration: underline;
  background-color: #285ac4;
  background: #ffffff;
  box-shadow: 0px 0px 0px;
}
</style> -->