import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Feed from '../views/Feed.vue'
import PassReset from '../views/PassReset.vue'
import Settings from '../views/Settings.vue'



const routes = [
  {path: '/', name: 'Home',component: Home},
  {path: '/login', name: 'Login',component: Login},
  {path: '/passreset', name: 'PassReset',component: PassReset},
  {path: '/register', name: 'Register',component: Register},
  {path: '/settings', name: 'Settings',component: Settings},
  {
    path: '/feed',
    name: 'Feed',
    component: () => import("../views/Feed.vue"),
    meta: {
      requiresAuth: true,
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const isPlaying = true;

export default router
