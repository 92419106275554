import  { defineStore } from 'pinia'

export const useTaskStore = defineStore('taskStore', {
    state: () => ({
        tasks: [
            {id: 1, title: "- TITULO 1", isFav: false},
            {id: 2, title: "- TITULO 2!!!!", isFav: false}
        ],
        name: 'Yoshi'
    })
})