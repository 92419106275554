import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFIrestore, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2UyOXs-DdIqd7GCycjsk3xQyNOxgUNFE",
  authDomain: "allpass-members.firebaseapp.com",
  projectId: "allpass-members",
  storageBucket: "allpass-members.appspot.com",
  messagingSenderId: "182187547446",
  appId: "1:182187547446:web:039817d2393b6cea983197",
  measurementId: "G-7EY5GLYCMN"
};

// Initialize Firebase
/* const app = initializeApp(firebaseConfig); */

initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const pinia = createPinia();
const app = createApp(App)

app.use(router).use(pinia)

app.mount('#app')

