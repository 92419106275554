<template>
  <div>
    <h1> Reset Password</h1>
<!--     <p>{{ TaskStore.name }}</p> -->
    <p><input type="text" placeholder="Email" v-model="email"/></p>
    <div class="errorMsg">
    <p v-if="errMsg">{{ errMsg }}</p> 
    </div>
    <div>
    </div>
    <p><button type="button" class="btn" @click="userLogout"><i class="fa fa-sign-in"></i> Reset Password</button></p>
    <p><button type="button" class="btnLink" @click="backToLogin"><i class="fa fa-arrow-left"></i> Back to Login</button></p>

</div>
</template>


<script setup>
import { ref } from "vue";
import { 
getAuth,
signInWithEmailAndPassword,
GoogleAuthProvider,
signInWithPopup
} from "firebase/auth";
import { useRouter } from "vue-router";
import { sendPasswordResetEmail } from "firebase/auth";

const email = ref("");
const password = ref("");
const errMsg = ref(); // ERROR MESSAGE
const router = useRouter();
const register = () => {
const auth = getAuth()
signInWithEmailAndPassword(auth, email.value, password.value)
.then((data) => {
  console.log("Succesfully Signed In!");
  console.log(auth.currentUser);
  router.push('/feed') // reditect to the feed
})
  .catch((error) => {
  console.log(error.code);
  switch(error.code){
    case "auth/invalid-email":
      errMsg.value = "Invalid email!";
      break;
    case "auth/user-not-found":
      errMsg.value = "No account with that email was found!";
      break;
    case "auth/wrong-password":
      errMsg.value = "Incorrect Password!";
      break;
    default:
      errMsg.value = "Email or Password was incorrect!";
      break;
  }
});
}; 

const userLogout = () => {
const auth = getAuth();
/* email = ref("marcelobruno@gmail.com") */
sendPasswordResetEmail(auth, email.value)
  .then(() => {
    router.push('/feed');

    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
}
const backToLogin = () => {
  router.push('/login');
}


</script>

