<template>
  <div class="settings">
    <h1> caca</h1>
    <h1>This is Home</h1>
  </div>
</template>

<style>
.settings {
  height: 500px;
}

</style>